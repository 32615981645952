<template>
  <div class="player">
    <div :id="`player-${player.id}`" style="width: 100%;"></div>
  </div>
</template>
<script>
import { reactive, onMounted } from 'vue';
import HlsPlayer from 'xgplayer-hls';
// import HlsJsPlayer from 'xgplayer-hls.js';

export default {
  props: {
    player: { type: Object }
  },
  setup(props) {
    console.log(props.player, '=====');

    const playerState = reactive({
      player: {}
    });

    const createPlayer = () => {
      // playerState.player = new HlsJsPlayer({
      //   id: `player-${props.player.id}`,
      //   url: props.player.pullAddressHls,
      //   autoplay: true,
      //   loop: true
      // });

      playerState.player = new HlsPlayer({
        id: `player-${props.player.id}`,
        url: props.player.pullAddressHls,
        autoplay: true,
        loop: true
      });

      document.getElementById(`player-${props.player.id}`).style.width = '100%';
    };

    const destroyPlayer = () => {
      playerState.player.destroy();
    };

    onMounted(() => {
      createPlayer();
    });

    return {
      createPlayer,
      destroyPlayer
    };
  }
};
</script>
